<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/markArea");
require("echarts/lib/component/toolbox");

export default {
    data() {
        return {
            showNoData: false,
            defoption: {
                title: {
                    text: "切线角预警",
                    textStyle: {
                        fontSize: 16,
                    },
                },
                color: [
                    "#00D2EF",
                    "#FF9E17",
                    "#4962FC",
                    "#f5a623",
                    "#dd3ee5",
                    "#12e78c",
                    "#fe8104",
                    "#01C2F9",
                    "#F4CB29",
                    "#FD9E06",
                ],
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {}
                    }
                },
                // backgroundColor:'#033c86',
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                legend: {
                    left: "center",
                    top: 5,
                    icon: "circle",
                    itemWidth: 8,
                    data: [],
                    textStyle: {
                        color: "#555",
                        fontSize: 14,
                    },
                },
                grid: {
                    top: "60",
                    left: "50",
                    right: "50",
                    bottom: "40",
                },
                xAxis: [
                    {
                        type: "category",
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                                margin: 15,
                            },
                            formatter: function (value) {
                                var t = value.split(' ')[0];
                                return t;
                            },
                            show: true,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        zlevel: 1,
                        data: [],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "变形速率(mm/d)",
                        nameTextStyle: {
                            color: "#555",
                            fontSize: 12,
                        },
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        min: (value) => {
                            return value.min < 0 ? Math.floor(value.min - 10) : 0;
                        },
                        max: (value) => {
                            console.log(value);
                            return Math.ceil(value.max + 10);
                        }
                    },
                    {
                        type: "value",
                        name: "切线角(°)",
                        nameTextStyle: {
                            color: "#555",
                            fontSize: 12,
                        },
                        interval: 10,
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        min: (value) => {
                            return value.min < 0 ? Math.floor(value.min - 10) : 0;
                        },
                        max: (value) => {
                            return Math.ceil(value.max + 10);
                        }
                    },
                ],
                series: [],
            },
        };
    },
    props: {
        yname: {
            type: String,
        },
        minInterval: {
            type: Number,
            default: 1,
        },
        option: {
            type: Object,
        },
        data: {
            type: Object,
        },
    },
    methods: {
        initChart() {
            this.defoption.yAxis[0].minInterval = this.minInterval;
            var chartOpt = Object.assign(this.defoption, this.option);
            chartOpt.xAxis[0].data = this.data.xAxis;
            console.log(this.data.xAxis);
            var series = this.data.series;
            chartOpt.legend.data = [];
            chartOpt.series = [];
            for (let index = 0; index < series.length; index++) {
                const element = series[index];
                chartOpt.legend.data.push(element.name);
                var item = {
                    name: element.name,
                    type: "line",
                    smooth: true,
                    yAxisIndex: element.name == "切线角" ? 1 : 0,
                    symbolSize: 0,
                    data: element.data,
                };

                var markAreaData = [];
                if (element.name == "切线角") {
                    var lastvalue = 0;
                    const colorArr = [{
                        name: '蓝色预警',
                        color: '#3498db',
                    }, {
                        name: '黄色预警',
                        color: '#f1c40f',
                    }, {
                        name: '橙色预警',
                        color: '#e67e22',
                    }, {
                        name: '红色预警',
                        color: '#e74c3c',
                    }];
                    element.data.forEach((dataitem, index) => {
                        var addtype = '';//add,update
                        var nowcolorindex;
                        if (dataitem >= 70 && dataitem < 75) {
                            if (lastvalue >= 70 && dataitem < 75) {
                                addtype = 'update';
                            } else {
                                addtype = 'add';
                                nowcolorindex = 0;
                            }
                        } else if (dataitem >= 75 && dataitem < 80) {
                            if (lastvalue >= 75 && dataitem < 80) {
                                addtype = 'update';
                            } else {
                                addtype = 'add';
                                nowcolorindex = 1;
                            }

                        } else if (dataitem >= 80 && dataitem < 85) {
                            if (lastvalue >= 80 && dataitem < 85) {
                                addtype = 'update';
                            } else {
                                addtype = 'add';
                                nowcolorindex = 2;
                            }

                        } else if (dataitem >= 85) {
                            if (lastvalue >= 85) {
                                addtype = 'update';
                            } else {
                                addtype = 'add';
                                nowcolorindex = 3;
                            }
                        }
                        if (addtype == 'add') {
                            markAreaData.push([
                                {
                                    name: colorArr[nowcolorindex].name,
                                    xAxis: chartOpt.xAxis[0].data[index],
                                    itemStyle: {
                                        color: colorArr[nowcolorindex].color,
                                    },
                                },
                                {
                                    xAxis: chartOpt.xAxis[0].data[index + 1],
                                },
                            ])
                        }
                        if (addtype == 'update') {
                            markAreaData[markAreaData.length - 1][1].xAxis = chartOpt.xAxis[0].data[index];
                        }
                        lastvalue = dataitem;
                    });
                    item.markArea = {
                        data: markAreaData
                    }
                }
                chartOpt.series.push(item);
            }

            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(chartOpt);
        },
    },
    mounted() {
        setTimeout(() => {
            this.initChart();
        }, 500);
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.initChart();
            },
        },
    },
};
</script>