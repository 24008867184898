<template>
  <div class="monitor-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select v-model="listfilter.dangerid" filterable remote reserve-keyword default-first-option clearable
              placeholder="请选择监测点" size="medium" :remote-method="getDangerList" :loading="getDangerLoading"
              @change="onSearch" v-if="deviceid == 0">
              <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName" :value="item.Id">
              </el-option>
            </el-select>

            <el-select style="margin-left: 10px" v-model="listfilter.level" placeholder="请选择预警等级" size="medium" clearable
              @change="onSearch">
              <el-option v-for="(item, index) in warningLevelSelect" :key="index" :label="item" :value="index + 1">
              </el-option>
            </el-select>
            <el-date-picker v-model="listfilter.fstarttime" type="date" size="medium" placeholder="开始日期"
              format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :clearable="true" style="width: 180px;margin-left:10px;"
              @change="onSearch">
            </el-date-picker>
            -
            <el-date-picker v-model="listfilter.fendtime" type="date" size="medium" placeholder="结束日期"
              format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :clearable="true" style="width: 180px;" @change="onSearch">
            </el-date-picker>
            <el-button style="margin-left: 10px" type="primary" size="medium" icon="el-icon-search"
              @click="onSearch">查询</el-button>
          </el-form>
        </div>
        <div class="action-right">
          <!-- <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="medium"
            @click="onAdd"
            >手动发布预警</el-button
          > -->
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
      :stripe="true" :border="false" v-loading="tableLoading">
      <el-table-column label="监测点名称" :show-overflow-tooltip="true" prop="DangerName"></el-table-column>
      <el-table-column label="监测点位名称" :show-overflow-tooltip="true" prop="MonitorName"></el-table-column>
      <el-table-column label="设备名称" :show-overflow-tooltip="true" prop="DeviceName"></el-table-column>
      <el-table-column label="预警等级" prop="WarningType">
        <template slot-scope="scope">
          {{ warningLevelSelect[scope.row.WarningType - 1] }}
        </template>
      </el-table-column>
      <el-table-column label="预警时间" prop="CreateTime">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="预警内容" prop="Content"></el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="small"
            @click="onEdit(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>

    <pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange">
    </pagination>


    <el-dialog :title="formtype == 'add' ? '手动发布预警' : '修改预警信息'" :visible.sync="dialogFormVisible" width="750px"
      custom-class="geology-dialog" :modal-append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
      :close-on-press-escape="false" @before-close="onFormClose">
      <el-form ref="form" class="geology-form" :rules="formRules" :model="form" label-width="120px"
        style="padding-top: 20px">
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点" prop="DangerId">
              <el-select v-model="form.DangerId" filterable remote reserve-keyword default-first-option
                placeholder="请选择监测点" size="medium" :remote-method="getDangerList" :loading="getDangerLoading"
                @change="onDangerChange">
                <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName" :value="item.Id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="监测点位" prop="MonitorId">
              <el-select v-model="form.MonitorId" filterable remote reserve-keyword default-first-option
                placeholder="请选择监测点位" size="medium" :remote-method="getMonitorList" :loading="getMonitorLoading"
                @change="onMonitorChange">
                <el-option v-for="item in monitorSelect" :key="item.Id" :label="item.MonitorName" :value="item.Id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="设备名称" prop="DeviceName">
              <el-input readonly="" v-model="form.DeviceName" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="预警类型" prop="WarningType">
              <el-select style="width: 100%" v-model="form.WarningType" placeholder="请选择预警类型" size="medium">
                <el-option v-for="(item, index) in warningLevelSelect" :key="index" :label="item" :value="index + 1">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="预警内容" prop="Content">
              <el-input type="textarea" :rows="5" v-model="form.Content" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit">立即保存</el-button>
              <el-button type="primary" plain size="medium" style="margin-left: 10px" @click="onFormClose">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

  </div>
</template>
<script>
import { getDangerList, getMonitorList, getMonitorDevice } from "@/api/base";
import { getWarningMsgList } from "@/api/device";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      filterDaterange: "",

      getDangerLoading: false,
      dangerSelect: [],
      getMonitorLoading: false,
      monitorSelect: [],

      warningLevelSelect: ["蓝色", "黄色", "橙色", "红色"],

      listfilter: {
        dangerid: "",
        deviceid: "",
        level: "",
        fstarttime: "",
        fendtime: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },



      dialogFormVisible: false,
      formtype: "add",
      form: {
        DangerId: "",
        MonitorId: "",
        DeviceName: "",
        WarningType: "",
        Content: "",
      },
      formRules: {
        DangerId: [
          { required: true, trigger: "blur", message: "请选择监测点" },
        ],
        MonitorId: [
          { required: true, trigger: "blur", message: "请选择监测点位" },
        ],
        WarningType: [
          { required: true, trigger: "blur", message: "请选择预警等级" },
        ],
        Content: [
          { required: true, trigger: "blur", message: "请输入预警内容" },
        ],
      },

    };
  },
  components: {},
  props: {
    deviceid: [String, Number],
    default: 0,
  },
  methods: {
    getDangerList(value) {
      this.getDangerLoading = true;
      getDangerList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDangerLoading = false;
        if (resdata.code == 0) {
          this.dangerSelect = resdata.data.data;
        }
      });
    },
    getMonitorList(value) {
      this.getMonitorLoading = true;
      getMonitorList(1, 10, {
        fname: value,
        dangerid: this.form.dangerid,
      }).then((resdata) => {
        this.getMonitorLoading = false;
        if (resdata.code == 0) {
          this.monitorSelect = resdata.data.data;
        }
      });
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      if (this.deviceid) {
        this.listfilter.deviceid = this.deviceid;
      }
      getWarningMsgList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onAdd() {
      this.form = {
        DangerId: "",
        MonitorId: "",
        DeviceName: "",
        WarningType: "",
        Content: "",
        Id: 0,
      };
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      item.DeviceName = "";
      item.DeviceCode = "";
      this.form = JSON.parse(JSON.stringify(item));
      this.formtype = "edit";
      this.dialogFormVisible = true;
      this.getMonitorList();
      this.onMonitorChange(this.form.MonitorId);
    },
    onDangerChange() {
      this.getMonitorList();
    },
    onMonitorChange(value) {
      getMonitorDevice(value).then(res => {
        if (res.code == 0 && res.data.length > 0) {
          var info = res.data[0];
          this.form.DeviceName = info.DeviceName;
          this.form.DeviceCode = info.DeviceCode;
          return;
        }
        this.form.DeviceName = '';
        this.form.DeviceCode = '';
        this.$message.error("未获取到设备信息!");
      })
    },
    onFormClose() {
      this.getListData();
      this.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addWarningAccept(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editWarningAccept(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getDangerList();
    this.getListData();
  },
};
</script>