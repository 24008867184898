<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
require("echarts/lib/chart/lines");
require("echarts/lib/chart/bar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/markArea");
require("echarts/lib/component/toolbox");

export default {
    data() {
        return {
            showNoData: false,
            defoption: {
                color: [
                    "#00D2EF",
                    "#FF9E17",
                    "#4962FC",
                    "#f5a623",
                    "#dd3ee5",
                    "#12e78c",
                    "#fe8104",
                    "#01C2F9",
                    "#F4CB29",
                    "#FD9E06",
                ],
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {}
                    }
                },
                // backgroundColor:'#033c86',
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                legend: {
                    left: "center",
                    top: 5,
                    icon: "circle",
                    itemWidth: 8,
                    data: [],
                    textStyle: {
                        color: "#555",
                        fontSize: 14,
                    },
                },
                grid: {
                    top: "60",
                    left: "50",
                    right: "50",
                    bottom: "40",
                },
                xAxis: [
                    {
                        type: "category",
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                                margin: 15,
                            },
                            formatter: function (value) {
                                var t = value.split(' ')[0];
                                return t;
                            },
                            show: true,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        zlevel: 1,
                        data: [],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "速度倒数",
                        nameTextStyle: {
                            color: "#555",
                            fontSize: 12,
                        },
                        axisLabel: {
                            show: false,
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        max: (value) => {
                            return value.max * 1.3;
                        }
                    },
                ],
                series: [],
            },
        };
    },
    props: {
        yname: {
            type: String,
        },
        minInterval: {
            type: Number,
            default: 1,
        },
        option: {
            type: Object,
        },
        chartdata: {
            type: Object,
        },
    },
    methods: {
        initChart() {
            console.log('初始化数据');
            this.defoption.yAxis[0].minInterval = this.minInterval;
            var chartOpt = Object.assign(this.defoption, this.option);
            var chartdata = JSON.parse(JSON.stringify(this.chartdata));
            let lastx = chartdata.xaxis[chartdata.xaxis.length - 1];
            if (chartdata.data.length > 0) {
                chartOpt.xAxis[0].data = chartdata.xaxis;
                for (let index = 0; index < 8; index++) {
                    chartOpt.xAxis[0].data.push(' ');
                }
                chartOpt.xAxis[0].data.push(chartdata.mintime);
                for (let index = 0; index < 10; index++) {
                    chartOpt.xAxis[0].data.push(' ');
                }
                chartOpt.xAxis[0].data.push(chartdata.maxtime);
                for (let index = 0; index < 10; index++) {
                    chartOpt.xAxis[0].data.push(' ');
                }
                chartOpt.xAxis[0].max = chartdata.xaxis.length + 20;
            }

            chartOpt.legend.data = [];
            chartOpt.series = [];
            var seriesdata = chartdata.data;
            var item = {
                name: '速度倒数',
                type: "line",
                data: seriesdata,
                markArea: {
                    data: [[
                        {
                            itemStyle: {
                                color: '#f1c40f',
                            },
                            coord: [0, 1]
                        }, {
                            coord: [lastx, 0]
                        }
                    ]]
                },
            };
            chartOpt.series.push(item);

            var that = this;
            chartOpt.series.push({
                type: 'lines',
                coordinateSystem: 'cartesian2d',
                lineStyle: {
                    width: 2,
                    type: 'dashed',
                    color: '#ae3ec9',
                },
                data: [{
                    name: '最小值',
                    coords: [
                        [lastx, seriesdata[seriesdata.length - 1]],
                        [chartdata.mintime, 0]
                    ],
                }, {
                    name: '最小值箭头',
                    lineStyle: {
                        width: 1,
                        type: 'solid',
                        color: '#4263eb',
                    },
                    symbol: ['none', 'arrow'],
                    coords: [
                        [chartdata.mintime, 0],
                        [chartdata.maxtime, seriesdata[seriesdata.length - 1]],
                    ],
                }, {
                    name: '最大值',
                    coords: [
                        [lastx, seriesdata[seriesdata.length - 1]],
                        [chartdata.maxtime, 0]
                    ],
                }, {
                    name: '最大值箭头',
                    symbol: ['none', 'arrow'],
                    lineStyle: {
                        width: 1,
                        type: 'solid',
                        color: '#4263eb',
                    },
                    label: {
                        show: true,
                        padding: [0, 40, 0, 0],
                        lineHeight: 16,
                        formatter: function () {
                            let mintime = that.$formatDate(new Date(chartdata.mintime), 'MM-dd hh:mm');
                            let maxtime = that.$formatDate(new Date(chartdata.maxtime), 'MM-dd hh:mm');
                            return '滑坡预报失稳时间\r\n(' + mintime + ' - ' + maxtime + ')';
                        }
                    },
                    coords: [
                        [chartdata.maxtime, 0],
                        [chartOpt.xAxis[0].data.length, seriesdata[seriesdata.length - 1]],
                    ],
                }],
            })

            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(chartOpt);
        },
    },
    mounted() {
        setTimeout(() => {
            if (this.chartdata && this.chartdata.data.length > 0) {
                this.initChart();
            }
        }, 500);
    },
    watch: {
        chartdata: {
            deep: true,
            handler() {
                this.initChart();
            },
        },
    },
};
</script>